export enum EStatusResposta {
    Sucesso = 1,
    DadosInvalidos = 2,
    Alerta =3,
    Erro = 4
}

export interface IRespostaMensagem {
    titulo: string;
    subMensagens: string[];
}

export interface IResposta {
    status: EStatusResposta;
    mensagens: IRespostaMensagem[];
    codigo: number;
}
