import { reactive } from 'vue';
import { message } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { IOption, IOptionString } from '../models/AntDesign/IOption';
import { IItemGenerico } from '../models/IItemGenerico';
import { IPaginacaoMetaData } from '../models/IPaginacaoMetaData';
import { EStatusResposta, IResposta } from '@/core/models/IResposta';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import router from '@/router';
import { IItemBuscaRapida } from '../models/Consulta/IItemBuscaRapida';

export interface IAppBase {
  identificadorPermissao: string;
  titulo: string;
  paginacao: IPaginacaoMetaData;
  debounce: number;
  carregando: boolean;
  apresentarBarraProgresso: boolean;
  fraseBarraProgresso: string;
  exibirComunicaoApi: boolean;
  resposta: IResposta;
}

export interface IRetornoAppBase{
  appBase: IAppBase;
  obterCodigoRota(): number;
  apresentarMensagemSucesso(mensagem: string):void;
  apresentarMensagemAlerta(mensagem: string):void;
  apresentarMensagemErro(mensagem: string):void;
  apresentarComunicacaoApi: (frase?: string) => void;
  ocultarComunicacaoApi: () => void;
  apresentarResposta: () => void;
  apresentarRespostaRedirecionamento(nomeRota: string): void;
  montaOpcoesBuscaRapida(listaItens: IItemBuscaRapida[]) : IOption[];
  montaOpcoesComListaItemGenerico(listaItens: IItemGenerico[]) : IOption[];
  montaOpcoesComListaItemGenericoTexto(listaItens: IItemGenerico[]) : IOptionString[]
}

export function useAppBase():IRetornoAppBase {
  const route = useRoute();

  const appBase = reactive({
    identificadorPermissao: '',
    titulo: String(route.meta.titulo),
    paginacao: {} as IPaginacaoMetaData,
    debounce: 0,
    carregando: false,
    apresentarBarraProgresso: false,
    fraseBarraProgresso: '',
    exibirComunicaoApi: false,
    resposta: {} as IResposta,
  });

  function obterCodigoRota(): number {
    let codigo = 0;

    if (route.params !== undefined && UtilitarioGeral.validaCodigo(route.params.codigo)) {
      codigo = Number(route.params.codigo);
    }

    return codigo;
  }
  function apresentarMensagemSucesso(mensagem: string) : void{
    message.success(mensagem);
  }

  function apresentarMensagemAlerta(mensagem: string) : void{
    message.warning(mensagem);
  }

  function apresentarMensagemErro(mensagem: string) : void{
    message.error(mensagem);
  }

  function apresentarComunicacaoApi(frase?: string) {
    appBase.apresentarBarraProgresso = true;
    appBase.exibirComunicaoApi = true;
    appBase.fraseBarraProgresso = frase === undefined ? '' : frase;
  }

  function ocultarComunicacaoApi() {
    appBase.fraseBarraProgresso = '';
    appBase.apresentarBarraProgresso = false;
    appBase.exibirComunicaoApi = false;
  }

  function apresentarResposta() {
    appBase.apresentarBarraProgresso = false;
    appBase.fraseBarraProgresso = '';
    appBase.exibirComunicaoApi = true;

    if (appBase.resposta.status === EStatusResposta.Sucesso) {
      clearTimeout(appBase.debounce);
      appBase.debounce = setTimeout(() => {
        appBase.exibirComunicaoApi = false;
      }, 3000);
    }
  }

  function apresentarRespostaRedirecionamento(nomeRota: string) {
    appBase.apresentarBarraProgresso = false;
    appBase.fraseBarraProgresso = '';
    appBase.exibirComunicaoApi = true;

    if (appBase.resposta.status === EStatusResposta.Sucesso) {
      clearTimeout(appBase.debounce);
      appBase.debounce = setTimeout(() => {
        appBase.exibirComunicaoApi = false;
        router.push({ name: nomeRota });
      }, 1500);
    }
  }

  function montaOpcoesBuscaRapida(listaItens: IItemBuscaRapida[]) : IOption[] {
    let listaOpcoes:IOption[] = [];
    listaOpcoes = listaItens.map((item) => ({
      label: item.textoIdentificacao,
      value: Number(item.codigo),
    }));

    return listaOpcoes;
  }

  function montaOpcoesComListaItemGenerico(listaItens: IItemGenerico[]) : IOption[] {
    let listaOpcoes:IOption[] = [];
    listaOpcoes = listaItens.map((item) => ({
      label: item.descricao,
      value: Number(item.identificador),
    }));

    return listaOpcoes;
  }

  function montaOpcoesComListaItemGenericoTexto(listaItens: IItemGenerico[]) : IOptionString[] {
    let listaOpcoes:IOptionString[] = [];
    listaOpcoes = listaItens.map((item) => ({
      label: item.descricao,
      value: item.identificador,
    }));

    return listaOpcoes;
  }

  return {
    appBase,
    obterCodigoRota,
    apresentarMensagemSucesso,
    apresentarMensagemAlerta,
    apresentarMensagemErro,
    apresentarComunicacaoApi,
    ocultarComunicacaoApi,
    apresentarResposta,
    apresentarRespostaRedirecionamento,
    montaOpcoesComListaItemGenerico,
    montaOpcoesComListaItemGenericoTexto,
    montaOpcoesBuscaRapida,
  };
}
