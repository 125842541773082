import { mask } from 'maska';
import { format } from 'v-money3';
import { IConfiguracaoVMoney } from '../models/Mascara/IConfiguracaoVMoney';
import UtilitarioGeral from './UtilitarioGeral';

export default class UtilitarioMascara {
  public static formatoMascaraDecimal = '#.###,##';

  public static formatoMascaraPercentual = '##,##';

  public static formatoMascaraNumeroInteiro = '["###", "#.###", "##.###","###.###", "#.###.###", "##.###.###", "###.###.###"]';

  public static formatoMascaraCEP = '##.###-###';

  public static formatoMascaraCPF = '###.###.###-##';

  public static formatoMascaraCNPJ = '##.###.###/####-##';

  public static formatoMascaraCPFeCNPJ = '["###.###.###-##", "##.###.###/####-##"]';

  public static formatoMascaraTelefoneFixo = '(##) ####-####';

  public static formatoMascaraTelefoneCelular = '(##) #####-####';

  public static formatoMascaraTelefoneFixoCelular = '["(##) ####-####", "(##) #####-####"]';

  public static formatoDiaMes = '##/##';

  static mascararCEP(valor: string):string {
    if (!UtilitarioGeral.valorValido(valor)) {
      return '';
    }
    return mask(valor, this.formatoMascaraCEP, { '#': { pattern: /[0-9]/ } });
  }

  static mascararDiaMes(valor: string):string {
    if (!UtilitarioGeral.valorValido(valor)) {
      return '';
    }
    return mask(valor, this.formatoDiaMes, { '#': { pattern: /[0-9]/ } });
  }

  static mascararCPF(valor: string):string {
    if (!UtilitarioGeral.valorValido(valor)) {
      return '';
    }
    return mask(valor, this.formatoMascaraCPF, { '#': { pattern: /[0-9]/ } });
  }

  static mascararCNPJ(valor: string):string {
    if (!UtilitarioGeral.valorValido(valor)) {
      return '';
    }
    return mask(valor, this.formatoMascaraCNPJ, { '#': { pattern: /[0-9]/ } });
  }

  static mascararCPFOuCNPJ(valor: string):string {
    if (!UtilitarioGeral.valorValido(valor)) {
      return '';
    }
    return mask(valor, this.formatoMascaraCPFeCNPJ, { '#': { pattern: /[0-9]/ } });
  }

  static mascararTelefoneFixoOuCelular(valor: string):string {
    if (!UtilitarioGeral.valorValido(valor)) {
      return '';
    }
    return mask(valor, this.formatoMascaraTelefoneFixoCelular, { '#': { pattern: /[0-9]/ } });
  }

  static removerMascara(valor: string):string {
    if (valor === undefined || valor === null || valor === '') {
      return '';
    }
    return valor.replace(/\D/g, '');
  }

  static removerMascaraNumero(valor: string):number {
    if (valor === undefined || valor === null) {
      return 0;
    }
    return Number(valor.toString().replace(/\D/g, ''));
  }

  static mascararNumeroInteiro(valor: number):string {
    if (!UtilitarioGeral.valorValido(valor.toString())) {
      return '';
    }
    return mask(valor.toString(), this.formatoMascaraNumeroInteiro, { '#': { pattern: /[0-9]/ } });
  }

  static configuracaoMonetaria(casasDecimais?: number, apresentarNegativo?:boolean): IConfiguracaoVMoney {
    const configuracaoVMoney:IConfiguracaoVMoney = {} as IConfiguracaoVMoney;
    configuracaoVMoney.masked = false;
    configuracaoVMoney.prefix = '';
    configuracaoVMoney.prefix = '';
    configuracaoVMoney.suffix = '';
    configuracaoVMoney.thousands = '.';
    configuracaoVMoney.decimal = ',';
    if (casasDecimais === undefined || casasDecimais === null) {
      configuracaoVMoney.precision = 2;
    } else {
      configuracaoVMoney.precision = casasDecimais;
    }
    if (apresentarNegativo === undefined) {
      configuracaoVMoney.disableNegative = true;
    } else {
      configuracaoVMoney.disableNegative = !apresentarNegativo;
    }
    configuracaoVMoney.disabled = false;
    configuracaoVMoney.allowBlank = false;
    configuracaoVMoney.minimumNumberOfCharacters = 0;
    return configuracaoVMoney;
  }

  static mascararValor(valor: number, casasDecimais?:number, apresentarMoeda?:boolean, apresentarNegativo?:boolean):string {
    const valorFormatado = format(valor, this.configuracaoMonetaria(casasDecimais, apresentarNegativo));
    if (apresentarMoeda !== undefined) {
      return `R$ ${valorFormatado}`;
    }
    return valorFormatado;
  }
}
