
import { defineComponent, computed, reactive } from 'vue';
import { EStatusResposta, IResposta } from '@/core/models/IResposta';
import BarraProgresso from './BarraProgresso.vue';

export default defineComponent({
  name: 'ComunicacaoApi',
  components: {
    BarraProgresso,
  },
  props: {
    visivel: {
      type: Boolean,
      required: true,
      default: false,
    },
    apresentarBarraProgresso: {
      type: Boolean,
      default: false,
    },
    fraseBarraProgresso: {
      type: String,
      default: '',
    },
    resposta: {
      type: Object as () => IResposta,
      required: true,
    },
  },
  setup(props, { emit }) {
    const dados = reactive({
      computedVisivel: computed({
        get: () => props.visivel,
        set: (val) => {
          emit('update:visivel', val);
        },
      }),
    });

    function fecharModal() {
      dados.computedVisivel = false;
    }

    return {
      props,
      dados,
      fecharModal,
      EStatusResposta,
    };
  },
});
